import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("Home");
  const [menuOpen, setMenuOpen] = useState(false);


  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setActiveLink('Home');
        break;
      case '/about':
        setActiveLink('About');
        break;
      case '/service':
        setActiveLink('Service');
        break;
      case '/career':
        setActiveLink('Career');
        break;
      case '/contact':
        setActiveLink('Contact');
        break;
      default:
        setActiveLink('Home');
    }

    // Scroll to top on path change
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setMenuOpen(false); // Close the menu when a link is clicked
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };



  return (
    <header className="header">
      <nav className="nav">
        <div className="container-fluid comn_spcing1 navbar_center">
          <div className="logo">
            <Link to="/" onClick={() => handleLinkClick("Home")}>
              <img src="assets/img/footer/logo_white1.svg" alt="Logo" />
            </Link>
          </div>
          <div id="mainListDiv" className={`main_list ${menuOpen ? 'show_list' : ''}`}>
            <ul className="navlinks menu">
              <li className={activeLink === "Home" ? "active" : ""}>
                <Link to="/" onClick={() => handleLinkClick("Home")}>
                  <img src="assets/img/home/home.svg" alt="Home" />
                </Link>
              </li>
              <li className={activeLink === "About" ? "active" : ""}>
                <Link to="/about" onClick={() => handleLinkClick("About")}>About Us</Link>
              </li>
              <li className={activeLink === "Service" ? "active" : ""}>
                <Link to="/service" onClick={() => handleLinkClick("Service")}>Services</Link>
              </li>
              <li className={activeLink === "Career" ? "active" : ""}>
                <Link to="/career" onClick={() => handleLinkClick("Career")}>Career</Link>
              </li>
              <li className={activeLink === "Contact" ? "active" : ""}>
                <Link to="/contact" onClick={() => handleLinkClick("Contact")}>Contact Us</Link>
              </li>
            </ul>
          </div>
          <span className={`navTrigger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <i className='bg-white' />
            <i className='bg-white' />
            <i className='bg-white' />
          </span>
        </div>
      </nav>
    </header>
  );
};

export default Header;

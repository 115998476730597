import React, { useState } from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [error, setError] = useState(false);
  const [addData, setAddData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
    file: ""
  });

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setAddData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!addData.name || !addData.email || !addData.message || !addData.number || !addData.file) {
      setError(true);
      return;
    }

    // try {
    //   await axios.post("https://:.com:7000/admin/addDatas", addData);
    //   console.log(addData);
    // } catch (error) {
    //   console.log("Something went wrong", error);
    // }
  };

  return (
    <>
      {/* ======= Footer section start=============== */}
      <div className="footer">
        <div className="ftr_top">
          <div className="container-fluid comn_spcing">
            <div className="row">
              <div className="col-lg-6 col-md-6 brdr_right pe-lg-5">
                <div className="top_sec">
                  <div className="text_sec">
                    <h1>Let's Grow Your Business Together</h1>
                  </div>
                  <div className="image_sec">
                    <img src="assets/img/footer/rotated-right-arrow.svg" alt="Arrow" />
                  </div>
                  <div className="btn_sec">
                    <a href="/contact" className="btn lets_talk">Let's Talk</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 ps-lg-5">
                <div className="top_sec">
                  <div className="text_sec">
                    <h1>"Explore New Career with Us - Reach Out Today!"</h1>
                  </div>
                  <div className="image_sec">
                    <img src="assets/img/footer/rotated-right-arrow.svg" alt="Arrow" />
                  </div>
                  <div className="btn_sec">
                    <a href="/career" className="btn lets_talk">Get In Touch</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer top section end */}

        {/* Footer middle section start */}
        <div className="ftr_main">
          <div className="container-fluid comn_spcing">
            <div className="row">
              {/* Footer logo & social section start */}
              <div className="col-lg-3 col-md-3">
                <div className="ftr_logo">
                  <a href="/">
                    <img src="assets/img/footer/logo_white1.svg" alt="Logo" />
                  </a>
                  <p>
                    At Lalit Mittal Technologies Pvt Ltd. we're not just in the business of technology; we're in the business of shaping the future. Join us on our journey as we push the boundaries of what's possible and deliver exceptional product-based solutions that define industry standards.
                  </p>
                </div>
                <ul className="ftr_social_icon">
                  <li className="list-inline-item tooltip-hover">
                    <a href="https://www.facebook.com/profile.php?id=61557189986966" target="_blank" rel="noopener noreferrer">
                      <img src="assets/img/footer/facebook.svg" alt="Facebook" />
                    </a>
                    <div className="tooltip-item">Facebook</div>
                  </li>
                  <li className="list-inline-item tooltip-hover">
                    <a href="https://www.linkedin.com/in/lalit-mittal-9155712bb/" target="_blank" rel="noopener noreferrer">
                      <img src="assets/img/footer/linkedin.svg" alt="LinkedIn" />
                    </a>
                    <div className="tooltip-item">LinkedIn</div>
                  </li>
                  <li className="list-inline-item tooltip-hover">
                    <a href="https://twitter.com/lalit98971" target="_blank" rel="noopener noreferrer">
                      <img src="assets/img/footer/x.svg" alt="Twitter" />
                    </a>
                    <div className="tooltip-item">Twitter</div>
                  </li>
                  <li className="list-inline-item tooltip-hover">
                    <a href="https://www.instagram.com/lmtechnologies.in/" target="_blank" rel="noopener noreferrer">
                      <img src="assets/img/footer/instagram.svg" alt="Instagram" />
                    </a>
                    <div className="tooltip-item">Instagram</div>
                  </li>
                </ul>
              </div>
              {/* Footer logo & social section end */}

              {/* Footer QUICK info section start */}
              <div className="col-lg-2 col-md-2 offset-lg-1 col-6">
                <div className="ftr_heading">
                  <h2>Our Company</h2>
                </div>
                <ul className="ftr_sub_heading">
                  <li><a href="/">Home</a></li>
                  <li><a href="/service">Services</a></li>
                  <li><a href="/about">About Us</a></li>
                  <li><a href="/career">Career</a></li>
                  <li><a href="/contact">Contact</a></li>
                </ul>
              </div>
              {/* Footer QUICK info section end */}

              {/* Footer service info section start */}
              <div className="col-lg-3 col-md-3 col-6">
                <div className="ftr_heading">
                  <h2>Our Service</h2>
                </div>
                <ul className="ftr_sub_heading">
                  <li><a href="/service">Mobile Development</a></li>
                  <li><a href="/service">Web Development</a></li>
                  <li><a href="/service">UI/UX Design</a></li>
                  <li><a href="/service">QA Development</a></li>
                </ul>
              </div>
              {/* Footer service info section end */}

              {/* Footer contact info section start */}
              <div className="col-lg-3 col-md-4 px-lg-0 px-md-0">
                <div className="ftr_heading">
                  <h2>Contact Info</h2>
                </div>
                <div className="contact_info">
                  <div className="icon">
                    <img src="assets/img/footer/call.png" alt="Call" />
                  </div>
                  <div className="details">
                    <p>
                      <a href="tel:+919988949675">+91-9988949675</a>
                    </p>
                  </div>
                </div>
                <div className="contact_info">
                  <div className="icon">
                    <img src="assets/img/footer/email.png" alt="Email" />
                  </div>
                  <div className="details">
                    <p>
                      <a href="mailto:contact@lalitmittaltechnologies.com" target="_blank" rel="noopener noreferrer">contact@lalitmittaltechnologies.com</a>
                    </p>
                  </div>
                </div>
                <div className="contact_info">
                  <div className="icon">
                    <img src="assets/img/footer/location.png" alt="Location" />
                  </div>
                  <div className="details">
                    <p>
                      <a href="https://maps.app.goo.gl/q3uejjz5bbADhsai9" target="_blank" rel="noopener noreferrer">2939, 2nd Floor, Hamilton Road, Kashmiri Gate, Delhi, 110006 (India)</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* Footer contact info section end */}
            </div>
          </div>
        </div>
        {/* Footer middle section end */}

        {/* Footer bottom section start */}
        <div className="ftr_btm">
          <div className="container-fluid comn_spcing">
            <div className="content_sec">
              <p><strong>© {currentYear} Lalit Mittal Technologies Pvt Ltd.</strong> All rights reserved.</p>
              <div>
                <p>
                  <a href="/terms-and-condition">Terms & Conditions</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  <a href="/privacy-policy">Privacy Policy</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  <a href="/faq">FAQ's</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Footer bottom section end */}
      </div>
      {/* ======= Footer section end=============== */}

      {/* Contact Us Modal */}
      <div className="modal" id="contact_us" aria-labelledby="contact_us">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Contact Us</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" />
            </div>
            <div className="modal-body">
              <div className="contact_frm p-0" style={{ border: 'none' }}>
                <div className="frm_middle" style={{ margin: 0 }}>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Your Name</label>
                          <input type="text" onChange={handleInputChange} className="effect-2 form-control" placeholder="Enter Your Name" name="name" required />
                          {error && !addData.name && <span className='error'>Please enter your name</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Email Address*</label>
                          <input type="email" onChange={handleInputChange} className="effect-2 form-control" pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" placeholder="Enter Your Email Address" name="email" required />
                          {error && !addData.email && <span className='error'>Please enter your email</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Contact Number*</label>
                          <input type="text" onChange={handleInputChange} className="effect-2 form-control" name="number" pattern="[0-9]{10}" placeholder="+91 123456789" required />
                          {error && !addData.number && <span className='error'>Please enter your number</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Message*</label>
                          <input type="text" onChange={handleInputChange} className="effect-2 form-control" name="message" placeholder="Enter your Message" required />
                          {error && !addData.message && <span className='error'>Please enter your message</span>}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="btn_submit">
                          <button type="submit" className="submit btn">Submit</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Hire Developers Modal */}
      <div className="modal" id="hire_developers" aria-labelledby="hire_developersLabel">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Hire Developers</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" />
            </div>
            <div className="modal-body">
              <div className="contact_frm p-0" style={{ border: 'none' }}>
                <div className="frm_middle" style={{ margin: 0 }}>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Your Name</label>
                          <input type="text" onChange={handleInputChange} className="effect-2 form-control" placeholder="Enter Your Name" name="name" required />
                          {error && !addData.name && <span className='error'>Please enter your name</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Email Address*</label>
                          <input type="email" onChange={handleInputChange} className="effect-2 form-control" pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" placeholder="Enter Your Email Address" name="email" required />
                          {error && !addData.email && <span className='error'>Please enter your email</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Contact Number*</label>
                          <input type="text" onChange={handleInputChange} className="effect-2 form-control" name="number" pattern="[0-9]{10}" placeholder="+91 123456789" required />
                          {error && !addData.number && <span className='error'>Please enter your number</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Upload Resume*</label>
                          <div className="input-group custom-file-button">
                            <span className="input-group-text">Upload</span>
                            <input type="file" onChange={handleInputChange} className="form-control w-100" name="file" required />
                          </div>
                          {error && !addData.file && <span className='error'>Please upload your resume</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form_spacing">
                          <label className="form-label">Message*</label>
                          <input type="text" onChange={handleInputChange} className="effect-2 form-control" name="message" placeholder="Enter your Message" required />
                          {error && !addData.message && <span className='error'>Please enter your message</span>}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="btn_submit">
                          <button type="submit" className="submit btn">Submit</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer section model end */}
    </>
  );
};

export default Footer;

import React from 'react';

const About = () => {
  const aboutCards = [
    {
      img: 'why.svg',
      title: 'Our Identity',
      text: 'We are a dynamic software company, driven by innovation and a passion for creating cutting-edge solutions that redefine the tech industry. Our commitment to continuous self-improvement empowers us to excel and fuels our ambitions.'
    },
    {
      img: 'vision.svg',
      title: 'Our Vision',
      text: 'We are a team of experienced experts crafting revolutionary websites and mobile applications. We are poised to shape the future of the digital world, known for delivering exceptional craftsmanship promptly and affordably.'
    },
    {
      img: 'mission.svg',
      title: 'Our Mission',
      text: 'Our mission revolves around creating exceptional, user-centric products. We thrive on active listening, providing fresh perspectives to tackle challenges, promoting collaboration, and harnessing the collective power of teamwork.'
    },
  ];

  const whatWeDoItems = [
    { title: 'Web Development', text: 'In today\'s digital world, it is essential to have a website and web application for all businesses. At Lalit Mittal, we build dynamic web applications and websites tailored to business needs.' },
    { title: 'Mobile App Development', text: 'As mobile users are increasing day by day, it is necessary to have a mobile-friendly application to represent your business. At Lalit Mittal, we develop Android and iOS apps using the latest technology.' },
    { title: 'Design & Research', text: 'An attractive design of a website and mobile app increases traffic and user engagement. We design and develop software based on business type.' },
    { title: 'Analytics Business', text: 'Our experienced team gathers full requirements and features before development. After analysis, we proceed with the development.' },
  ];

  const coreValuesItems = [
    { title: 'Process-Oriented', text: 'At Lalit Mittal, we take pride in our commitment to well-defined processes. Each team follows these processes diligently to ensure consistent delivery of high-quality work.' },
    { title: 'Smart Working', text: 'We embrace smart work methodologies, focusing on efficiency and effectiveness. Our dedication to managing time and quality allows us to provide high-quality work timely. For our team members, smart work fosters innovative and creative thinking, enabling them to choose the most effective approaches.' },
    { title: 'Growth', text: 'Growth is at the heart of our mission. We prioritize the growth of our staff and our company. We believe that continuous growth should be the driving force behind all our business activities.' },
  ];

  return (
    <>
      <section id="about" className="section">
        {/* ======= about component start =============== */}

        {/*------- about banner section start ----------- */}
        <div className="carrier_bnr">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="bnr_left">
                  <img
                    src="assets/img/about/about_banner.png"
                    loading="lazy"
                    width={500}
                    alt="About Banner"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 m-lg-auto m-md-auto">
                <div className="bnr_right">
                  <div className="text_section">
                    <h1>About Us</h1>
                    <p>
                      At Lalit Mittal Technologies Pvt Ltd., where innovation meets expertise in the world of software solutions. We are a dynamic and forward-thinking software development company dedicated to crafting cutting-edge solutions that empower businesses and individuals alike.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*------- about banner section end ----------- */}

        {/*----------- What We Can Do section start ------------ */}
        <section className="pb-4 bg-color-f8f8f8">
          <div className="page-banner-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-6">
                  <div className="page-banner-heading">
                    <h1 className="title font-weight-bold mb-2">What We Do?</h1>
                    <ul className="everything-items">
                      {whatWeDoItems.map((item, index) => (
                        <li key={index}>
                          <strong>{item.title} -</strong> {item.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6">
                  <div className="page-banner-img mt-4">
                    <img
                      className="img-fluid"
                      src="assets/img/about/about3.svg"
                      loading="lazy"
                      width={500}
                      alt="What We Do"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*----------- What We Can Do section end ------------ */}

        {/*------- about card section start ----------- */}
        <div className="about_bnr">
          <div className="container z_index">
            <div className="about_heading">
              <h1>About Lalit Mittal</h1>
              <p>
                With a decade of experience, we specialize in creating stunning digital products that captivate audiences and drive tangible business results. Our mastery of technology transforms online traffic into success.
              </p>
            </div>
            <div className="row">
              {aboutCards.map((card, index) => (
                <div key={index} className="col-lg-4 col-md-4">
                  <div className="about_card">
                    <img
                      src={`assets/img/about/${card.img}`}
                      loading="lazy"
                      alt={card.title}
                    />
                    <div className="text_sec">
                      <h3>{card.title}</h3>
                      <p>{card.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/*------- about card section end ----------- */}

        {/* -----------Start Page Banner Area ----------------*/}
        <section className="bg-color-f8f8f8">
          <div className="page-banner-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-6">
                  <div className="page-banner-img">
                    <img
                      className="img-fluid"
                      src="assets/img/about/about1.png"
                      loading="lazy"
                      alt="About Us"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-4">
                  <div className="page-banner-heading mt-4">
                    <h1>About Us</h1>
                    <p>
                      This is your shot at becoming a member of the fastest-growing software solutions company in India! As a software development company in India, Lalit Mittal Technologies Pvt Ltd. creates opportunities to work with the top 1% of tech talent. A place for your career to grow tremendously, as you learn from the best in the industry. Enjoy a culture-rich workplace, where every little occasion is celebrated with love and laughter.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*----------- End Page Banner Area -------------*/}

        {/*----------- Our Core Values section start ------------ */}
        <section className="pb-4 bg-color-f8f8f8">
          <div className="page-banner-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-6">
                  <div className="page-banner-heading">
                    <h1 className="title font-weight-bold mb-2">Our Core Values</h1>
                    <ul className="everything-items">
                      {coreValuesItems.map((item, index) => (
                        <li key={index}>
                          <strong>{item.title}:</strong> {item.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-7 col-md-6">
                  <div className="page-banner-img">
                    <img
                      className="img-fluid"
                      src="assets/img/about/about4.png"
                      loading="lazy"
                      width={450}
                      alt="Core Values"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*----------- Our Core Values section end ------------ */}

        {/* ======= about component end =============== */}
      </section>
    </>
  );
};

export default About;

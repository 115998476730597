import { Route, Routes, Outlet } from "react-router-dom";
import Footer from './Component/Footer/Footer';
import Header from './Component/Header/Header';
import Home from './Component/Home/Home';
import Faq from './Component/Quick-Links/Faq/Faq';
import PrivacyPolicy from './Component/Quick-Links/Privacy-Policy/PrivacyPolicy';
import TermsConditions from "./Component/Quick-Links/Terms-Conditions/TermsConditions";
import Contact from "./Component/Contact/Contact";
import Service from "./Component/Service/Service";
import About from './Component/About/About';
import Career from './Component/Career/Career';
import ErorrPage from "./Component/Quick-Links/ErorrPage/ErorrPage";


function App() {
  return (
    <div>
      <Routes>
        {/* Default layout with Header and Footer */}
        <Route
          path="/"
          element={
            <div>
              <Header />
              <Outlet />
              <Footer />
            </div>
          }
        >
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          {/* Add other routes as needed */}
          <Route path="/terms-and-condition" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<Faq />} />
        </Route>

       
        {/* Error page layout without Header and Footer */}
        <Route path="*" element={<ErorrPage />} />
      </Routes>
    </div>
  );
}

export default App;


